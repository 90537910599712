import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";

class PrivacyPolicy extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <Header/>
                <div className="container p3">
                    <div className="txt">
                        <h1 class="header">Polityka prywatności</h1>
                        <div class="row">
                        <div class="small-12 column">&nbsp;</div>
                        </div>
                        <div class="content-module">
                        <div class="content">
                        <p>Zachowanie poufności danych naszych Klient&oacute;w i Kontrahent&oacute;w oraz przejrzystość w ich przetwarzaniu jest dla nas niezwykle ważna, dlatego sporządziliśmy nową Politykę prywatności, kt&oacute;ra opisuje spos&oacute;b ochrony i przetwarzania danych osobowych.</p>
                        <p>&nbsp;</p>
                        <p>Administratorem danych osobowych jest firma Extend Vision Sp. z o.o., z siedzibą w Krakowie, przy ul. Kamiennej 43, 31-403 Krak&oacute;w, wpisaną do Rejestru Przedsiębiorc&oacute;w, prowadzonego przez Sąd Rejonowy Krak&oacute;w-Śr&oacute;dmieście w Krakowie, XI Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000103098, nr REGON 351152198, nr NIP: 945-15-15-490.</p>
                        <p>&nbsp;</p>
                        <p><strong>Gdzie przechowujemy Twoje dane?</strong></p>
                        <p><br />Zebrane dane osobowe przechowujemy na terenie Europejskiego Obszaru Gospodarczego (&bdquo;EOG&rdquo;), na ścieśe zabezpieczonych serwerach. Każda operacja przesyłania i przetwarzania danych osobowych jest wykonywana zgodnie z obowiązującym prawem.</p>
                        <p>&nbsp;</p>
                        <p><strong>Kto ma dostęp do Twoich danych?</strong><br />&nbsp;</p>
                        <p>Dostęp do Twoich danych ma tylko i wyłącznie firma Extend Vision.</p>
                        <p>Nigdy nie przekazujemy Twoich danych, nie sprzedajemy ich ani nie wymieniamy się nimi w celach marketingowych z podmiotami spoza firmy Extend Vision. Dane przekazywane podmiotom zewnętrznym są używane tylko do świadczenia Ci naszych usług.</p>
                        <p>&nbsp;</p>
                        <p><strong>Jakie masz prawa?</strong></p>
                        <p><br />Pamiętaj, że w każdej chwili masz prawo zażądać informacji o tym, jakie Twoje dane osobowe przechowujemy. Masz prawo otrzymać kopii tych danych. Możesz r&oacute;wnież zażadać poprawienia swoich danych osobowych, jeśli są nieprawidłowe lub uzupełnienia danych niekompletnych.</p>
                        <p>W każdej chwili masz prawo usunąć jakiekolwiek dane przetwarzane przez Extend Vision, z wyjątkiem następujących sytuacji:</p>
                        <p><br />* masz otwarte zam&oacute;wienie, kt&oacute;re nie zostało jeszcze wysłane lub zostało wysłane tylko częściowo<br />* masz nieuregulowany dług wobec Extend Vision, niezależnie od metody płatności<br />* Tw&oacute;j dług został sprzedany firmie zewnętrznej w ciągu ostatnich trzech lat lub jednego roku (w przypadku klient&oacute;w, kt&oacute;rzy zmarli)<br />* Tw&oacute;j wniosek kredytowy został odrzucony w ciągu ostatnich trzech miesięcy<br />* Jeśli dokonałaś(eś) jakikolwiek zakup&oacute;w, zachowamy Twoje dane osobowe dotyczące transakcji w celach księgowych.</p>
                        <p>&nbsp;</p>
                        <p>Masz prawo do niewyrażenia zgody na przetwarzanie danych na podstwie uzasadnionego interesu Extend Vision.</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <p>Przysługuje Ci r&oacute;wnież prawo do niewyrażenia zgody na marketing bezpośredni, w tym także na profilowanie.</p>
                        <p>&nbsp;</p>
                        <p>Możesz zrezygnować z otrzymywania materiał&oacute;w marketingu bezpośredniego w następujący spos&oacute;b:</p>
                        <p>* wykonując instrukcje zawarte w każdej takiej wiadomości e-mail<br />* wysyłając maila na adres&nbsp;<a href="mailto:biuro@extendvision.pl">biuro@extendvision.pl</a>&nbsp;z tytułem: Do Inspektora Ochrony Danych Osobowych &ndash; cofnięcie zgody na marketing bezpośredni</p>
                        <p>&nbsp;</p>
                        <p>Masz prawo zażądać ograniczenia przetwarzania Twoich danych osobowych pod następującymi warunkami:</p>
                        <p>* jeśli nie zgodzisz się na przetwarzanie danych na podstawie uzasadnionego interesu Extend Vision, wtedy Extend Vision ograniczy jakiekolwiek przetwarzanie takich danych po zweryfikowaniu, czy istnieje taki uzasadniony interes.<br />* jeśli zgłosisz, że Twoje dane osobowe są niepoprawne, wtedy Extend Vision musi ograniczyć jakiekolwiek przetwarzanie danych do momentu zweryfikowania ich poprawności.<br />* jeśli przetwarzanie danych jest niezgodne z prawem, wtedy możesz nie zgodzić się na wykasowanie danych osobowych i zamiast tego zażądać ograniczenia używania Twoich danych osobowych.<br />* jeśli Extend Vision nie potrzebuje już Twoich danych osobowych, ale są one wymagane do zgłoszenia lub oddalenia roszczenia.</p>
                        <p>&nbsp;</p>
                        <p><strong>Jak możesz korzystać ze swoich praw?</strong></p>
                        <p><br />Bardzo poważnie podchodzimy do ochrony danych, dlatego wyznaczyliśmy osoby odpowiedzialne, za odpowiadanie na pytania dotyczące powyższych kwestii. Aby się z nimi skontaktować, napisz na adres&nbsp;<a href="mailto:biuro@extendvision.pl">biuro@extendvision.pl</a>.</p>
                        <p>&nbsp;</p>
                        <p><strong>Inspektor Ochrony Danych Osobowych:</strong><br />&nbsp;</p>
                        <p>Aby zagwarantować, że Twoje dane zawsze będą przetwarzane wiernie, w spos&oacute;b transparentny i zgodny z prawem, wyznaczyliśmy Inspektora Ochrony Danych Osobowych. Aby skontaktować się z Inspektorem Ochrony Danych Osobowych, napisz na adres biuro@extendvision.pl a w temacie wiadomości wpisz &bdquo;Do Inespektora ochrony Danych Osobowych&rdquo;.</p>
                        <p>&nbsp;</p>
                        <p><strong>Prawo zgłoszenia skargi w organie nadzorczym:&nbsp;&nbsp;</strong><br />&nbsp;</p>
                        <p>Jeśli uważasz, że Extend Vision przetwarza Twoje dane osobowe w niewłaściwy spos&oacute;b, możesz się z nami skontaktować. Masz też prawo zgłosić skargę w organie nadzorczym.</p>
                        <p>&nbsp;</p>
                        <p><strong>Uaktualnienia naszych Zasad prywatności:</strong><br />&nbsp;</p>
                        <p>W przyszłości może zajść konieczność aktualizacji Zasad prywatności. Ich najnowsza wersja będzie zawsze dostępna na naszej stronie. Będziemy informować o każdej zmianie treści Zasad prywatności, na przykład o zmianach dotyczących celu, w jakim korzystamy z Twoich danych osobowych, tożsamości Inspektora Ochrony Danych Osobowych lub Twoich praw.</p>
                        </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );

    }
}

export default PrivacyPolicy;

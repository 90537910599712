import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import Catalog from './components/catalog/Catalog';
import CategoriesPage from './components/categories_page/CategoriesPage';
import GalleriesPage from './components/galleries_page/GalleriesPage';
import Gallery from './components/galleries/Gallery';
import SubcategoriesPage from './components/subcategories_page/SubcategoriesPage';
import Product from './components/products/Product';
import Regulations from './components/regulations/Regulations';
import PrivacyPolicy from './components/regulations/PrivacyPolicy';

class App extends Component {

    render(){
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route path='/kontakt' component={Contact}/>
                    <Route path='/katalog' component={Catalog}/>
                    <Route path='/galeria' component={GalleriesPage}/>
                    <Route path='/nasze-realizacje/:galleryUrl' component={Gallery}/>
                    <Route path='/regulamin' component={Regulations}/>
                    <Route path='/polityka-prywatnosci' component={PrivacyPolicy}/>
                    <Route path='/kategorie/:categoryUrl' component={CategoriesPage}/>
                    <Route path='/produkty/:productUrl' component={Product}/>
                    <Route path='/podkategorie/:categoryUrl/:subcategoryUrl' component={SubcategoriesPage}/>
                </Switch>
            </BrowserRouter>
        );
    }

}

export default App;

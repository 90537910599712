import React, {Component} from 'react';
import Header from './../header/Header';
import Banner from './../banner/Banner';
import {Link} from "react-router-dom";
import Categories from "../categories/Categories";
import axios from "axios";
import {config} from "./../../config";
import Footer from './../footer/Footer';
import Galleries from './../galleries/Galleries';

import Parser from 'html-react-parser';

class GalleriesPage extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    render() {

        return (
                <div>
                    <Header/>
                    <Banner/>
                    <div className="container p-3">
                        <div className="home">
                            <div className="row">
                                <div className="col-md-4">
                                    <Categories/>
                                </div>
                                <div className="col-md-8">
                                    <div className="txt">
                                        <br/>
                                        <br/>
                                        <h1>Nasze realizacje</h1>
                                        <hr/>
                                        <br/>
                                        <Galleries/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );

    }
}

export default GalleriesPage;

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "./Contact.css";
import Header from "../header/Header";

class Contact extends Component {

    constructor() {
        super();
        this.state = {
        };
    }


    render() {
        return (
            <div>
                <Header/>
                <div className="container p3">
                    <div className="contact">
                        <div className="row">
                            <div className="col-md-6">
                                    <h1>Kontakt z nami</h1>
                                    <br/>
                                    <p>Expozycja.com</p>
                                    <p>ul. Kamienna 43</p>
                                    <p>31-403 Kraków</p>
                                    <p>+48 12 627 68 28</p>
                                    <a className="contact-email" href="mailto:biuro@expozycja.com">biuro@expozycja.com</a>
                                    <br/>
                                    <br/>
                                    <p><strong>Godziny otwarcia:</strong> 9.00 - 17.00</p>
                                    <br/>
                                    <h3>Dane do faktury:</h3>
                                    <br/>
                                    <p>Extend Vision sp. z o.o.</p>
                                    <p>ul. Kamienna 43</p>
                                    <p>31-403 Kraków</p>
                                    <p><strong>NIP:</strong> 945-15-15-490</p>
                            </div>
                            <div className="col-md-6">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" scrolling="no"
                                            src="https://maps.google.com/maps?q=expozycja.com%20Krak%C3%B3w&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default Contact;

import React, {Component} from 'react';
import "./Catalog.css";
import Header from "../header/Header";
import BackendResponse from "../feedback/BackendResponse";
import Footer from './../footer/Footer';
import axios from "axios";
import {config} from "./../../config";
import catalog_img from './../../img/katalog.png';
import catalog_img2 from './../../img/katalog2.png';

class Catalog extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            contactPerson: "",
            phone: "",
            address: "",
            agreement1: false,
            agreement2: false,
            agreement3: false,
            backendMessage: "",
            status: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let stateName = event.target.id;
        let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({
            [stateName]: value
        });
    }


    handleSubmit(event) {

        event.preventDefault();
        
        const postData = {
            email: this.state.email,
            contactPerson: this.state.contactPerson,
            phone: this.state.phone,
            address: this.state.address,
            agreement1: this.state.agreement1,
            agreement2: this.state.agreement2,
            agreement3: this.state.agreement3
        };

        axios.post(config.apiUrl + 'catalog.php/makeOrder', postData).then(
            response => {
                this.setState({
                        backendMessage: response.data.message,
                        status: response.data.status
                    });
            }
        );
    }


    render() {

        return (
            <div>
                <Header/>
                <div className="container p3">
                    <div className="catalog">
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Formularz zamówienia katalogu Expozycja.com</h3>
                                <br/>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label>Adres email (*)</label>
                                        <input  onChange={this.handleChange} placeholder="Adres email" type="email" className="form-control" id="email"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Osoba kontaktowa (*)</label>
                                        <input  onChange={this.handleChange} placeholder="Osoba kontaktowa" type="text" className="form-control" id="contactPerson"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Adres do wysyłki (*)</label>
                                        <input  onChange={this.handleChange} placeholder="Adres do wysyłki" type="text" className="form-control" id="address"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Numer telefonu</label>
                                        <input  onChange={this.handleChange} placeholder="Numer telefonu (opcjonalny)" type="text" className="form-control" id="phone"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Akceptuję politykę prywatności i regulamin serwisu Expozycja.com (*)</label>
                                        <input  onChange={this.handleChange} type="checkbox" className="form-control" id="agreement1"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Chcę otrzymywać e-maile o promocjach</label>
                                        <input  onChange={this.handleChange} type="checkbox" className="form-control" id="agreement2"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Chcę być telefonicznie informowany o promocjach</label>
                                        <input  onChange={this.handleChange} type="checkbox" className="form-control" id="agreement3"/>
                                    </div>
                                    <button type="submit" className="btn btn-secondary btn-block">Zamów katalog</button>
                                    <BackendResponse status={this.state.status} backendMessage={this.state.backendMessage}/>
                                </form>
                                <hr/>
                                <br/>
                                <p>Pobierz <strong>elektroniczną wersję katalogów</strong> Expozycja.com:</p>
                                <p>
                                    <a href="https://drive.google.com/file/d/12Q6yF972XwqlaDyRMFyFpj6HDZZIeXdS/view" target="_blank">Katalog produkt&oacute;w</a>
                                </p>
                                <p>
                                    <a href="https://drive.google.com/file/d/17o6DsZgGexlUVQVDuIVa5p5le_IQ3grm/view" target="_blank">Katalog konstrukcji panelowych SORT</a>
                                </p>
                                <br/>
                                <hr/>
                            </div>
                            <div className="col-md-6">
                                    <img src={catalog_img} alt="Katalog Expozycja.com"/>
                                    <br/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );

    }
}

export default Catalog;

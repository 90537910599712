import React, {Component} from 'react';
import axios from 'axios';
import {config} from "./../../config";
import "./Products.css";
import ProductThumbnail from './ProductThumbnail';

class Products extends Component {

    constructor() {
        super();
        this.state = {
            products: []
        };
        this.loadProducts = this.loadProducts.bind(this);
    }

    loadProducts() {

        if(this.props.categoryID) {

            const postData = {
                categoryID: this.props.categoryID
            };
            
            axios.post(config.apiUrl + 'products.php/byCategoryID', postData).then(
                response => {
                    this.setState({
                        products: response.data.data
                    })
                }
            );

        }

        if(this.props.subcategoryID) {

            const postData = {
                subcategoryID: this.props.subcategoryID
            };
            
            axios.post(config.apiUrl + 'products.php/bySubcategoryID', postData).then(
                response => {
                    this.setState({
                        products: response.data.data
                    })
                }
            );

        }

    }

    componentDidMount() {
        this.loadProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.categoryID !== this.props.categoryID || prevProps.subcategoryID !== this.props.subcategoryID) {
            this.loadProducts();
        }

    }

    render() {

        if(this.state.products.length > 0) {
            return(
                <div className="products-card">
                    <div className="products">
                        {this.state.products.map((value, index) => {
                            return <ProductThumbnail
                                product={value}
                                key={index.toString()}
                            />
                        })}
                    </div>
                </div>
            );
        } else {
            return(
                <>
                </>
            );
        }

    }

}

export default Products;
import React, {Component} from 'react';
import Header from './../header/Header';
import Banner from './../banner/Banner';
import Footer from './../footer/Footer';
import {Link} from "react-router-dom";
import Categories from "../categories/Categories";
import Products from "./Products";
import axios from "axios";
import {config} from "./../../config";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import Parser from 'html-react-parser';

class Product extends Component {

    constructor() {
        super();
        this.state = {
            status: "",
            product: [],
            productDescription: "",
            galleryImages: []
        };
    }

    componentDidMount() {

        let { productUrl } = this.props.match.params;
        
        this.setState({
            productUrl: productUrl
        });

        const productUrlData = {
            productUrl: productUrl
        };

        axios.post(config.apiUrl + 'products.php/byProductUrl', productUrlData).then(
            response => {
                if(response.data.status.toString() === "1") {
                    this.setState({
                        product: response.data.data,
                        status: response.data.status,
                        productDescription: response.data.data[0].productDescription
                    });
                    const productGalleryUrlData = {
                        productID: response.data.data[0].productID
                    }
                    axios.post(config.apiUrl + 'product_gallery.php/getPhotos', productGalleryUrlData).then(

                        response => {
                            if(response.data.status.toString() === "1") {
                                let productGallery = [];
                                for(let e of response.data.data) {
                                    let newElement = {
                                        original: config.productImgUrl + e.productID + '/' + e.imgName,
                                        thumbnail: config.productImgUrl + e.productID + '/' + e.imgName
                                    };
                                    productGallery.push(newElement);
                                }
                                this.setState({
                                    galleryImages: productGallery
                                });
                            }
                        }

                    ).catch( err => {
                        this.setState({
                            status: false
                        });
                    });
                }
            }
        ).catch(err => {
            this.setState({
                status: false
            });
        });
        

    }

    render() {


        if(this.state.status.toString() === "1") {

            if(this.state.galleryImages.length > 0) {
                return (
                    <div>
                        <Header/>
                        <Banner/>
                        <div className="container p-3">
                            <div className="home">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Categories/>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="txt">
                                            <h1>{this.state.product[0].productName}</h1>
                                            <hr/>
                                            <ImageGallery items={this.state.galleryImages} />
                                            <br/>
                                            {Parser(this.state.productDescription)}
                                            <hr/>
                                            <h3>Zobacz inne produkty w tej kategorii:</h3>
                                            <br/>
                                            <Products subcategoryID={this.state.product[0].subcategoryID}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            } else {
                return (
                    <div>
                        <Header/>
                        <Banner/>
                        <div className="container p-3">
                            <div className="home">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Categories/>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="txt">
                                            <h1>{this.state.product[0].productName}</h1>
                                            <hr/>
                                            {Parser(this.state.productDescription)}
                                            <hr/>
                                            <img src={this.state.product[0].productImageUrl} alt={this.state.product[0].productName} className="img-fluid"/>
                                            <hr/>
                                            <h3>Zobacz inne produkty w tej kategorii:</h3>
                                            <br/>
                                            <Products subcategoryID={this.state.product[0].subcategoryID}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }

        if(this.state.status.toString() === "false") {
            return (
                <div>
                    <Header/>
                    <Banner/>
                    <div className="container-fluid p-3">
                        <div className="home">
                            <div className="row">
                                <div className="col-md-4">
                                    <Categories/>
                                </div>
                                <div className="col-md-8">
                                    <div className="txt">
                                        Nie odnaleziono takiego produktu, sprawdź adres URL.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }

        return <></>;

    }
}

export default Product;

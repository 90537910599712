import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "./Subcategory.css";
import {config} from "../../config";

class Category extends Component {

    constructor() {
        super();
        this.state = {
        };
    }



    render() {

        return(
            <li className="subcategory">
                <a href={"/podkategorie/" + this.props.parentCategory.categoryUrl + "/" + this.props.subcategory.subcategoryUrl}>
                    {this.props.subcategory.subcategoryName}
                </a>
            </li>
        );




    }
}

export default Category;

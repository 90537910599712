import React, {Component} from 'react';
import {config} from "../../config";
import "./ProductThumbnail.css";

class ProductThumbnail extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    render() {

        return(
            <div className="product-thumbnail">
                <a href={"/produkty/" + this.props.product.productUrl}>
                    <div className="product-thumbnail-link">
                        <p><strong>{this.props.product.productName}</strong></p>
                        <img src={this.props.product.productImageUrl} alt={this.props.product.productName} className="img-fluid"/>
                    </div>
                </a>
                <hr/>
            </div>
        );

    }

}

export default ProductThumbnail;
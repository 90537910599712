import React, {Component} from 'react';
import Header from './../header/Header';
import Banner from './../banner/Banner';
import Footer from './../footer/Footer';
import {Link} from "react-router-dom";
import Categories from "../categories/Categories";
import {config} from "./../../config";
import "./Home.css";

class Home extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <Header/>
                <Banner/>
                <div className="container p-3">
                    <div className="home">
                        <div className="row">
                            <div className="col-md-4">
                                <Categories/>
                            </div>
                            <div className="col-md-8">
                                <div className="txt">
                                    <br/>
                                    <br/>
                                    <br/>
                                    <p className="title-main">DESIGN YOUR SPACE</p>
                                    <hr/>
                                    <p>Witamy serdecznie na naszej stronie Expozycja.com - w świecie aranżacji przestrzeni.
                                    Expozycja.com zajmuje sie <strong>sprzedażą oraz projektowaniem systemów ekspozycyjnych</strong>.
                                    Tworzymy <strong>kompleksowe rozwiązania dla przestrzeni komercyjnych i publicznych</strong>:</p>
                                    <ul>
                                        <li>muzeów</li>
                                        <li>galerii</li>
                                        <li>biur</li>
                                        <li>instytucji</li>
                                        <li>sklepów</li>
                                        <li>hoteli</li>
                                        <li>restauracji</li>
                                        <li>galerii sztuki</li>
                                        <li>galerii handlowych</li>
                                        <li>przestrzeni komercyjnych</li>
                                        <li>i wiele innych</li>
                                    </ul>
                                    <p>Jesteśmy po to, by zapewnić naszym Klientom nowatorskie produkty najwyższej jakości, kreatywne koncepcje i ambitne pomysły. Oferujemy bogatą gamę produktów stosowanych w branży handlowo-wystawienniczej, które umożliwiają zaprojektowanie wyjątkowej przestrzeni. Proponujemy nowoczesne i eleganckie systemy ekspozycyjne i wystawiennicze: systemy linkowe oraz systemy rurkowe do podwieszeń, innowacyjne konstrukcje panelowe SORT, słupki odgradzające, kołki dystansowe, stojaki, ekspozytory i totemy reklamowe, ramy na wydruki, a także systemy plenerowe. Projektowane przez nas wnętrza wzbogacamy o systemy podświetlenia LED. Zajmujemy się także aranżacją witryn.</p>
                                    <p>Oferujemy systemy, które łączą ze sobą <strong>funkcjonalność i wyjątkowy wygląd</strong>. Nasze produkty dostosowujemy do indywidualnych wymagań klientów.</p>
                                    <p>Specjalizujemy się w <strong>doradztwie projektowym</strong>. Nasze bogate zaplecze graficzne pozwala nam na tworzenie innowacyjnych <strong>projektów i wizualizacji 3D</strong>. Z chęcią umówimy się z Państwem na spotkanie w sprawie ustalenia szczegółów realizacji.</p>
                                    <p>Dzięki bogatemu zapleczu technologicznemu oferujemy również bogaty wachlarz tworzyw sztucznych, <strong>wydruki i montaż.</strong></p>
                                    <p>Każdego dnia dokładamy wszelkich starań by zapewnić naszym Klientom najwyższą jakość proponowanych usług. Gwarantujemy fachowy montaż oraz doradztwo w zakresie systemów ekspozycyjnych i wystawienniczych. Każdego z naszych Klientów traktujemy wyjątkowo i indywidualnie, dopasowując produkty pod względem zarówno wizualnym, jak i cenowym. Po otrzymaniu zapytania przedstawiamy wizualizację z zastosowaniem zaproponowanego systemu dopasowanego do wnętrza.</p>
                                    <p>Zapraszamy do obejrzenia <a className="txt-href" href="https://drive.google.com/file/d/12Q6yF972XwqlaDyRMFyFpj6HDZZIeXdS/view">katalogu produktów</a> lub <a className="txt-href" href="https://drive.google.com/file/d/17o6DsZgGexlUVQVDuIVa5p5le_IQ3grm/view">katalogu konstrukcji panelowych SORT</a>.</p>
                                    <p>Efekty naszej pracy można oglądać w <a className="txt-href" href="/galeria">Galerii Realizacji</a>.</p>
                                    <p><strong>Certyfikat Top Design Award</strong> <strong>2010&nbsp;</strong>dla konstrukcji panelowych SORT.</p>
                                    <br/>
                                    <br/>
                                    <p className="title-submain">TOP EXPO PRODUKTY</p>
                                    <hr/>
                                    <br/>
                                    <div className="top-expo-produkty-mid">
                                        <img className="img-top-expo" src={config.bannerUrl + "banery_glowna/panel_pick.png"} alt="" />
                                        <img className="img-top-expo" src={config.bannerUrl + "banery_glowna/konstrukcje_2.jpg"} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="top-expo-produkty-mid">
                            <img className="img-top-expo" src={config.bannerUrl + "banery_glowna/kwadrat_baner1.jpg"} alt="" />
                            <img className="img-top-expo" src={config.bannerUrl + "banery_glowna/kwadrat_baner2.jpg"} alt="" />
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Home;

import React, {Component} from 'react';
import BannerItem from './BannerItem';
import "./Banner.css";
import axios from "axios";
import {config} from "./../../config";

class Banner extends Component {

    constructor() {
        super();
        this.state = {
            banners: [],
            status: ""
        };
    }

    componentDidMount() {
        axios.get(config.apiUrl + 'banners.php/getActive').then(
            response => {
                if(response.data.status.toString() === "1") {
                    this.setState({
                        banners: response.data.data,
                        status: response.data.status
                    });
                }
            }
        ).catch(err => {
            this.setState({
                status: false
            });
        });

    }

    render() {

        if(this.state.status.toString() === "1") {

            return(
                <div className="container">
                    <div className="banner">
                        <BannerItem banners={this.state.banners} />
                    </div>
                </div>
            );

        } else {
            return(
                <div className="container">
                    <div className="banner">
                    </div>
                </div>
            );
        }

    }
}

export default Banner;

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "./Header.css";
import logo from './../../img/logo.png';

class Header extends Component {

    constructor() {
        super();
        this.state = {
        };
    }


    render() {
        return (
            <header>
                <div className="container p-3 text-center">
                    <nav>
                    <div className="header-custom">
                        <div className="row align-items-center">
                            <div className="col-md-3">
                                <Link className="white-link" to="/">
                                    <img src={logo} className="img-fluid" alt="Expozycja.com"/>
                                </Link>
                                <br/>
                                <br/>
                            </div>
                            <div className="col-md-3">

                            </div>
                            <div className="col-md-6">
                                <div className="row align-items-center">
                                    <div className="col-3">
                                        <Link to="/galeria">
                                            Galeria
                                        </Link>
                                    </div>
                                    <div className="col-3">
                                        <Link to="/katalog">
                                            Katalog
                                        </Link>
                                    </div>
                                    <div className="col-3">
                                        <a className="red-link" href="https://www.wystawa.pl/">
                                            Stoiska
                                        </a>
                                    </div>
                                    <div className="col-3">
                                        <Link to="/kontakt">
                                            Kontakt
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    </nav>
                    <hr/>
                </div>
            </header>
        );

    }
}

export default Header;

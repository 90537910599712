import React, {Component} from 'react';
import axios from "axios";
import {config} from "./../../config";
import "./Categories.css";
import Category from './Category';

class Categories extends Component {

    constructor() {
        super();
        this.state = {
            status: "",
            categories: [],
            innerWidth: window.innerWidth
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        axios.get(config.apiUrl + 'categories.php/getAll').then(
            response => {
                if(response.data.status.toString() === "1") {
                    this.setState({
                        categories: response.data.data,
                        status: response.data.status
                    });
                }
            }
        ).catch(err => {
            this.setState({
                status: false
            });
        });

    }


    handleResize() {
        this.setState({
            innerWidth: window.innerWidth

        });
    }

    render() {

        window.addEventListener('resize', this.handleResize);

        if(this.state.status.toString() === "1") {

            if(this.state.innerWidth <= 768) {
                return (
                    <nav>
                        <div className="container">
                            <button className="btn btn-outline-danger btn-block" data-toggle="collapse" data-target="#collapseCategories">Filtruj</button>
                            <div id="collapseCategories" className="collapse">
                                <div className="categories">
                                    {this.state.categories.map((value, index) => {
                                        return <Category category={value} key={index.toString()}/>
                                    })}
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </nav>
                );
            } else {
                return(
                    <nav>
                        <div className="container">
                            <div className="categories">
                                {this.state.categories.map((value, index) => {
                                    return <Category category={value} key={index.toString()}/>
                                })}
                            </div>
                        </div>
                    </nav>
                );
            }

        }

        if(this.state.status.toString() === "false") {
            return(
                <nav>
                    <div className="container">
                        <div className="categories">
                            <div className="alert alert-danger">
                                <p><strong>Something went wrong ;(</strong></p>
                                <p>Please try again later...</p>
                            </div>
                        </div>
                    </div>
                </nav>
            );
        }

        return <></>;
    }
}

export default Categories;

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "./Footer.css";

class Footer extends Component {

    constructor() {
        super();
        this.state = {
        };
    }


    render() {
        return (
                
                <div className="container p-3">
                    <hr/>
                    <footer>
                        <div className="footer-custom">
                            <div className="row">
                                <div className="col-md-4">
                                    <a className="red-link" href="/">
                                        Expozycja.com
                                    </a>
                                    <br/>
                                    <a href="/kontakt">
                                        Kontakt
                                    </a>
                                    <br/>
                                    <a href="/katalog">
                                        Zamów katalog
                                    </a>
                                </div>
                                <div className="col-md-4">
                                    <a href="/regulamin">
                                        Regulamin
                                    </a>
                                    <br/>
                                    <a href="/polityka-prywatnosci">
                                        Polityka prywatności
                                    </a>
                                    <br/>
                                    <a href="/kategorie/kariera">
                                        Kariera
                                    </a>
                                </div>
                                <div className="col-md-4">
                                    <a href="https://www.facebook.com/expozycjabyextendvision/">
                                        Facebook
                                    </a>
                                    <br/>
                                    <a href="https://pl.pinterest.com/expozycja/">
                                        Pinterest
                                    </a>
                                    <br/>
                                    <a href="https://www.youtube.com/channel/UCJkmOAqT1hIvXijMAejtoeQ">
                                        YouTube
                                    </a>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
        );

    }
}

export default Footer;

import React, {Component} from 'react';
import {config} from "../../config";
import "./GalleryThumbnail.css";

class GalleryThumbnail extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    render() {

        return(
            <div className="col-md-3">
                <div className="gallery-thumbnail">
                    <a href={"/nasze-realizacje/" + this.props.gallery.galleryUrl}>
                        <div className="gallery-thumbnail-link">
                            <img src={config.imgUrl + this.props.gallery.galleryID + "/" + this.props.gallery.galleryImage} alt={this.props.gallery.galleryName}/>
                            <br/>
                            <br/>
                            <p><strong>{this.props.gallery.galleryName}</strong></p>
                        </div>
                    </a>
                </div>
                <br/>
            </div>
        );

    }

}

export default GalleryThumbnail;
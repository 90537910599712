import React, {Component} from 'react';
import Header from './../header/Header';
import Banner from './../banner/Banner';
import {Link} from "react-router-dom";
import Categories from "../categories/Categories";
import axios from "axios";
import {config} from "./../../config";
import Products from "../products/Products";
import Footer from './../footer/Footer';

import Parser from 'html-react-parser';

class CategoriesPage extends Component {

    constructor() {
        super();
        this.state = {
            status: "",
            categories: [],
            categoryDescription: ""
        };
    }

    componentDidMount() {

        let { categoryUrl } = this.props.match.params;
        
        this.setState({
            categoryUrl: categoryUrl
        });

        const categoryData = {
            categoryUrl: categoryUrl
        };

        axios.post(config.apiUrl + 'categories.php/byCategoryUrl', categoryData).then(
            response => {
                if(response.data.status.toString() === "1") {
                    this.setState({
                        categories: response.data.data,
                        status: response.data.status,
                        categoryDescription: response.data.data[0].categoryHTML,
                        categoryID: response.data.data[0].categoryID
                    });
                }
            }
        ).catch(err => {
            this.setState({
                status: false
            });
        });
        

    }

    render() {

        if(this.state.status.toString() === "1") {
            return (
                <div>
                    <Header/>
                    <Banner/>
                    <div className="container p-3">
                        <div className="home">
                            <div className="row">
                                <div className="col-md-4">
                                    <Categories/>
                                </div>
                                <div className="col-md-8">
                                    <div className="txt">
                                        <br/>
                                        <br/>
                                        <h1>{this.state.categories[0].categoryName}</h1>
                                        <hr/>
                                        {Parser(this.state.categoryDescription)}
                                        <Products categoryID={this.state.categoryID}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }

        if(this.state.status.toString() === "false") {
            return (
                <div>
                    <Header/>
                    <Banner/>
                    <div className="container p-3">
                        <div className="home">
                            <div className="row">
                                <div className="col-md-4">
                                    <Categories/>
                                </div>
                                <div className="col-md-8">
                                    <div className="txt">
                                        Coś poszło nie tak ;(
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <></>;

    }
}

export default CategoriesPage;

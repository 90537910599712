import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";

class Regulations extends Component {

    constructor() {
        super();
        this.state = {
        };
    }


    render() {
        return (
            <div>
                <Header/>
                <div className="container p3">
                    <div className="txt">
                        <h1 class="header">Regulamin</h1>
                        <div class="row">
                        <div class="small-12 column">&nbsp;</div>
                        </div>
                        <div class="content-module">
                        <div class="content">
                        <p>REGULAMIN USŁUGI "BRIEF" ORAZ "FORMULARZ KONTAKTOWY"</p>
                        <p>&nbsp;</p>
                        <ol>
                        <li>POSTANOWIENIA OG&Oacute;LNE</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>1.1. Korzystając z usług "Brief" lub "Formularz kontaktowy" użytkownik akceptuje postanowienia niniejszego regulaminu.</p>
                        <p>1.2. Dostawcą usługi jest firma Extend Vision z siedzibą przy ul. Kamienna 43, 31-403 KRAK&Oacute;W, wpisana do Krajowego Rejestru Sądowego pod numerem 0000103098.</p>
                        <p>&nbsp;</p>
                        <ol start="2">
                        <li>WARUNKI ZAMAWIANIA USŁUGI</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>2.1. Z usług można skorzystać prawidłowo wypełniając formularz dostępny na witrynie internetowej www.wystawa.pl.</p>
                        <p>2.2. Dostawca usługi nie ponosi odpowiedzialności za wpisy utracone, uszkodzone lub niewłaściwie wprowadzone.</p>
                        <p>2.3. Jeżeli co najmniej jedna z wymaganych w formularzu informacji nie zostanie podana lub nie będzie spełniała przyjętych wymog&oacute;w, zgłoszenie będzie uznane za nieprawidłowe i usunięte, a co za tym idzie nie zostanie zrealizowane. Podobnie, jeżeli kt&oacute;rakolwiek z informacji okaże się fałszywa, zgłoszenie będzie uznane za nieprawidłowe i usunięte.</p>
                        <p>2.4. Każdy subskrybent ponosi pełną odpowiedzialność odszkodowawczą wobec Extend Vision w przypadku jakichkolwiek działań os&oacute;b trzecich skierowanych przeciwko Extend Vision w związku z podaniem niedokładnych danych przy zam&oacute;wieniu usług "Brief" lub "Formularz kontaktowy".</p>
                        <p>2.5. Extend Vision zastrzega sobie prawo do zmiany lub modyfikacji niniejszych zasad korzystania z usług "Brief" lub "Formularz kontaktowy" w dowolnym czasie bez uprzedniego powiadomienia i poinformowania o takowych zmianach lub modyfikacjach, poprzez umieszczenie zaktualizowanej wersji danego dokumentu na stronie internetowej.</p>
                        <p>&nbsp;</p>
                        <ol start="3">
                        <li>PRZEDMIOT USŁUGI</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>3.1. Przedmiotem usługi jest kontakt przedstawiciela handlowego firmy Extend Vision z usługobiorcą, wizyta przedstawiciela handlowego firmy Extend Vision w siedzibie usługobiorcy, przesłanie do usługobiorcy kompletu informacji o produktach firmy Extend Vision lub dostarczenie usługobiorcy hasła do specjalnej części serwisu internetowego firmy Extend Vision.</p>
                        <p>3.2. Zakres usługi wybierany jest przez usługobiorcę poprzez zaznaczenie odpowiednich p&oacute;l w formularzu zgłoszeniowym.</p>
                        <p>&nbsp;</p>
                        <ol start="4">
                        <li>PRZETWARZANIE DANYCH OSOBOWYCH</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>4.1. Uznaje się, że poprzez kliknięcie/zaznacznie "Akceptuję regulamin" w niniejszej witrynie usługobiorca w spos&oacute;b jednoznaczny akceptuje zasady niniejszego Regulaminu.</p>
                        <p>4.2. Usługobiorca korzystając z usług "Brief" lub "Formularz kontaktowy" jednocześnie zgadza się na bezterminowe wykorzystanie i przetwarzanie przekazanych danych tj. adresu poczty elektronicznej, imienia, nazwiska, nazwy firmy, branży, numeru telefonu, numeru faksu, adresu oraz innych danych eksploatacyjnych (adres (IP), informacja o komputerze i podłączeniu, takie jak typ przeglądarki i wersja systemu operacyjnego, analityka ruchu, historia interakcji, pełen przebieg sekwencji jednolitych lokalizator&oacute;w zasob&oacute;w (URL) w ruchu do, w ramach i ze stron internetowych Extend Vision, wykorzystywanych do świadczenia usługi do cel&oacute;w reklamy, badania rynku oraz zachowań i preferencji z przeznaczeniem wynik&oacute;w tych badań na potrzeby polepszenia jakości usług świadczonych przez Extend Vision oraz umieszczenie ich w bazie danych, prowadzonej przez Extend Vision w postaci danych niezanonimizowanych. Zgoda ta jest dobrowolna. Dane osobowe związane z korzystaniem z usług "kontakt z nami" lub "strona na hasło" przechowywane będą w Bazie Danych klient&oacute;w usług "kontakt z nami" lub "strona na hasło" , przetwarzane i wykorzystywane dla cel&oacute;w wykonania ww. usług przez firmę Extend Vision. Usługobiorca może w dowolnym momencie domagać się dostępu do swoich danych osobowych i ich modyfikacji, zwracając się pisemnie do Extend Vision pod wyżej wskazany adres.</p>
                        <p>4.3. Subskrybent wyraża zgodę na świadczenie usługi przesyłania drogą elektroniczną informacji handlowych o produktach firmy Extend Vision zgodnie z ustawą o świadczeniu usług drogą elektroniczną oraz wykorzystanie zebranych danych dla potrzeb kontakt&oacute;w za pośrednictwem poczty elektronicznej celem poinformowania o nowych produktach, usługach lub promocjach lub też celem odpowiedzi na pytania, realizowania zleceń, zindywidualizowania i rozbudowy witryny internetowej oraz innych usług Extend Vision lub indywidualnego komunikowania się w związku z usługami "Brief" lub "Formularz kontaktowy".</p>
                        <p>4.4. Extend Vision będzie przetwarzała dane do czasu zakończenia świadczenia usług "kontakt z nami" lub "strona na hasło".</p>
                        <p>4.5. Extend Vision zapewnia przesyłanie danych bezpiecznym połączeniem internetowym.</p>
                        <p>4.6. Extend Vision przestrzega zasady respektowania prywatności. Informacje o charakterze osobistym przekazywane przez daną osobę podczas rejestracji, w trakcie korzystania z usług Extend Vision nie zostaną ujawnione jakimkolwiek osobom trzecim. Usługobiorca wyraża zgodę, aby podmioty powiązane z Extend Vision, dostawcy usług oraz inni podwykonawcy świadczący usługi związane z Extend Vision, wsp&oacute;łpracownicy posiadali dostęp do danych subskrybent&oacute;w potrzebnych dla świadczenia określonych usług. Przy tym powyższe podmioty spełniające odnośne funkcje zaakceptowały stosowne zobowiązania dotyczące zachowania poufności przy postępowaniu z danymi osobowymi usługobiorcy lub przekazanymi przez niego informacjami.</p>
                        <p>4.7. Extend Vision zamieszczać będzie na komputerze subskrybent&oacute;w informacje umożliwiające identyfikację komputera subskrybenta. Informacje te nazywane są powszechnie "cookies". Cookies stosowane są powszechnie przez liczne witryny internetowe. Informacje te, wskazując w jaki spos&oacute;b i kiedy subskrybenci korzystają ze strony internetowej Extend Vision, mogą być pomocne w celu ciągłego ulepszania strony internetowej Extend Vision i jej usług. Extend Vision stosować będzie cookies w celu dostępu do tych informacji na twardym dysku, kt&oacute;re zostały tam umieszczone przez cookie z witryny internetowej Extend Vision</p>
                        <p>4.8. Extend Vision nie będzie monitorować lub ujawniać jakichkolwiek danych osobowych lub informacji o takim charakterze zamieszczonych przez subskrybenta w witrynie internetowej Extend Vision, o ile nie jest to konieczne w związku przepisami prawa, z legalnymi żądaniami władz lub w ramach proces&oacute;w toczących się z udziałem firmy Extend Vision w celu ochrony i obrony praw Extend Vision, ochrony osobistego bezpieczeństwa subskrybent&oacute;w, klient&oacute;w Extend Vision lub bezpieczeństwa publicznego. Usługobiorca niniejszym upoważnia Extend Vision do ujawnienia organom wykonawczym lub innym organom rządowym wszelkich informacji dotyczących jego osoby, jakie Extend Vision, według swojego wyłącznego uznania, uważa za konieczne lub właściwe lub jakie są wymagane przez powyższe władze.</p>
                        <p>4.9. Extend Vision może na życzenie subskrybenta lub ze swej własnej inicjatywy, uzupełnić, sprostować lub też usunąć jakiekolwiek niepełne, niezgodne lub nieaktualne dane osobowe przechowywanie w związku z usługami "Brief" lub "Formularz kontaktowy".</p>
                        <p>&nbsp;</p>
                        <ol start="5">
                        <li>REZYGNACJA Z USŁUGI</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>5.1. Usługobiorca może w dowolnym momencie zrezygnować z usługi, zablokować hasło dostępu, a tym samym wycofać zgodę na użytkowanie i przetwarzanie danych osobowych. Rezygnacji z subskrypcji można dokonać pisząc na adres Extend Vision, ul. Kamienna 43, 31-403 KRAK&Oacute;W przekazując pełne imię i nazwisko usługobiorcy oraz adres e-mail lub wysyłając e-mail na adres biuro@extendvision.pl przekazując pełne imię i nazwisko usługobiorcy oraz adres e-mail.</p>
                        <p>5.2. W przypadku stwierdzenia naruszenia przez usługobiorcę niniejszego Regulaminu lub jakichkolwiek warunk&oacute;w i zasad związanych z innymi usługami, lub złożenia nieprawdziwego oświadczenie lub też innego sposobu niewłaściwego postępowania Extend Vision może wypowiedzieć świadczenie usług bez wcześniejszego powiadomienia.</p>
                        <p>&nbsp;</p>
                        <ol start="6">
                        <li>OGRANICZENIE ODPOWIEDZIALNOŚCI I OGRANICZONA GWARANCJA</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>6.1. Extend Vision nie gwarantuje stałej dostępności stron internetowych ani ich bezbłędnego funkcjonowania z wyjątkiem zastrzeżeń zawartych w poniższych postanowieniach.</p>
                        <p>6.2. Extend Vision nie ponosi odpowiedzialności za okresowy brak dostępu do witryny www.wystawa.pl. Jak r&oacute;wnież za op&oacute;źnienia, utratę danych ani za szkody powstałe w czasie transmisji danych z witryny do i od subskrybenta.</p>
                        <p>6.3. W zakresie dozwolonym przez odpowiednie przepisy prawa Extend Vision nie ponosi odpowiedzialności za roszczenia z tytułu niewykonania lub nienależytego wykonania umowy, czyn&oacute;w niedozwolonych, zaniedbania lub z innych tytuł&oacute;w, wynikające z:</p>
                        <p>a)utraty zysku, przychod&oacute;w, dobrego imienia, spodziewanych oszczędności lub danych; lub</p>
                        <p>b)wszelkich pośrednich lub wt&oacute;rnych strat lub uszkodzeń.</p>
                        <p>&nbsp;</p>
                        <ol start="7">
                        <li>INNE POSTANOWIENIA</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>7.1. Extend Vision zastrzega sobie prawo do dokonania zmian, modyfikacji lub wycofania dostępu do świadczonych usług w dowolnym momencie, bez lub za wcześniejszym powiadomieniem oraz nie ponosząc za to odpowiedzialności, chyba że warunki i zasady dotyczące określonej usługi stanowią inaczej.</p>
                        <p>7.2. Nieważność dowolnego z postanowień regulaminu nie wyklucza stosowania pozostałych postanowień. W miejsce nieważnego postanowienia stosowany będzie ważny przepis, kt&oacute;rego znaczenie jest najbliższe celom i efektom funkcjonowania niniejszego Regulaminu.</p>
                        <p>7.3. Użytkownik nie może w żaden spos&oacute;b wykorzystywać Znak&oacute;w występujących w Witrynie bez uzyskania pisemnego zezwolenia firmy Extend Vision, o ile odpowiednie powszechnie obowiązujące przepisy prawa nie stanowią inaczej.</p>
                        <p>&nbsp;</p>
                        <p>8.REKLAMACJE</p>
                        <p>&nbsp;</p>
                        <p>8.1. Wszystkie reklamacje mogą być zgłaszane w formie wiadomości e-mail na adres poczty elektronicznej biuro@extendvision.pl. Wiadomość taka opr&oacute;cz treści reklamacji musi zawierać imię, nazwisko oraz adres poczty elektronicznej usługobiorcy.</p>
                        <p>&nbsp;</p>
                        <ol start="9">
                        <li>POSTANOWIENIA KOŃCOWE</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>9.1. Niniejszy regulamin jest jedynym dokumentem określającym zasady korzystania z usługi.</p>
                        <p>9.2. Extend Vision zastrzega sobie prawo do zmiany lub modyfikacji niniejszych zasad korzystania z usług "Brief" lub "Formularz kontaktowy" w dowolnym czasie bez uprzedniego powiadomienia i poinformowania o takowych zmianach lub modyfikacjach, poprzez umieszczenie zaktualizowanej wersji danego dokumentu na stronie internetowej.</p>
                        </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );

    }
}

export default Regulations;

import React, {Component} from 'react';
import {config} from "./../../config";

class BannerItem extends Component {

    constructor() {
        super();
        this.state = {
            counter: 0
        };
        this.changeBanner = this.changeBanner.bind(this);
    }

    changeBanner() {

        if(this.state.counter == this.props.banners.length - 1) {
            let newCounter = 0;
            this.setState({
                counter: newCounter
            });
            return;
        }

        if(this.state.counter < this.props.banners.length - 1) {
            let newCounter = this.state.counter + 1;
            this.setState({
                counter: newCounter
            });
        }

    }

    componentDidMount() {

        setInterval(this.changeBanner, 5000);

    }

    render() {

        return(
                <a href={config.websiteUrl + this.props.banners[this.state.counter].bannerUrl}>
                    <picture>
                        <source srcSet={config.bannerUrl + this.props.banners[this.state.counter].bannerPhotoBig} alt={this.props.banners[this.state.counter].bannerName} media="(min-width: 768px)"/>
                        <img src={config.bannerUrl + this.props.banners[this.state.counter].bannerPhotoSmall} alt={this.props.banners[this.state.counter].bannerName} />
                    </picture>
                </a>
        );

    }
}

export default BannerItem;

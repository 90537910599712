import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "./Category.css";
import axios from "axios";
import {config} from "../../config";
import Subcategory from "./Subcategory";

class Category extends Component {

    constructor() {
        super();
        this.state = {
            status: "",
            subcategories: [],
            targetUrl: ""
        };
    }

    componentDidMount() {
        const categoryData = {
            categoryID: this.props.category.categoryID
        };

        this.setState({
            targetUrl: "/kategorie/" + this.props.category.categoryUrl
        });

        axios.post(config.apiUrl + 'subcategories.php/byCategoryID', categoryData).then(
            response => {
                if(response.data.status.toString() === "1") {
                    this.setState({
                        subcategories: response.data.data,
                        status: response.data.status
                    });
                }
            }
        ).catch(err => {
            this.setState({
                status: false
            });
        });

    }


    render() {

        if(this.state.status.toString() === "1") {
            return(
                <div className="category-group">
                    <div className="category">
                        <a href={this.state.targetUrl}>
                            {this.props.category.categoryName}
                        </a>
                    </div>
                    <div className="subcategories">
                        <ul>
                            {this.state.subcategories.map((value, index) => {
                                return <Subcategory
                                    parentCategory={this.props.category}
                                    subcategory={value}
                                    key={index.toString()}
                                />
                            })}
                        </ul>
                    </div>
                </div>
            );
        }

        if(this.state.status.toString() === "false") {
            return(
                <div>
                    <div className="subcategories">
                        <div className="alert alert-danger">
                            <p><strong>Something went wrong ;(</strong></p>
                            <p>Please try again later...</p>
                        </div>
                    </div>
                </div>
            );
        }

        return <></>;

    }
}

export default Category;

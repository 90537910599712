import React, {Component} from 'react';
import Header from './../header/Header';
import Banner from './../banner/Banner';
import {Link} from "react-router-dom";
import Categories from "../categories/Categories";
import axios from "axios";
import {config} from "./../../config";
import Products from "../products/Products";
import Footer from './../footer/Footer';

import Parser from 'html-react-parser';

class SubcategoriesPage extends Component {

    constructor() {
        super();
        this.state = {
            status: "",
            subcategories: [],
            subcategoryDescription: ""
        };
    }

    componentDidMount() {

        let { categoryUrl, subcategoryUrl } = this.props.match.params;
        
        this.setState({
            categoryUrl: categoryUrl,
            subcategoryUrl: subcategoryUrl
        });

        const subcategoryData = {
            categoryUrl: categoryUrl,
            subcategoryUrl: subcategoryUrl
        };

        axios.post(config.apiUrl + 'subcategories.php/bySubcategoryUrl', subcategoryData).then(
            response => {
                if(response.data.status.toString() === "1") {
                    this.setState({
                        subcategories: response.data.data,
                        status: response.data.status,
                        subcategoryDescription: response.data.data[0].subcategoryHTML,
                        subcategoryID: response.data.data[0].subcategoryID
                    });
                }
            }
        ).catch(err => {
            this.setState({
                status: false
            });
        });
        

    }

    render() {

        if(this.state.status.toString() === "1") {
            return (
                <div>
                    <Header/>
                    <Banner/>
                    <div className="container p-3">
                        <div className="home">
                            <div className="row">
                                <div className="col-md-4">
                                    <Categories/>
                                </div>
                                <div className="col-md-8">
                                    <div className="txt">
                                        <br/>
                                        <br/>
                                        <h1>{this.state.subcategories[0].subcategoryName}</h1>
                                        <hr/>
                                        {Parser(this.state.subcategoryDescription)}
                                        <Products subcategoryID={this.state.subcategoryID}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }

        if(this.state.status.toString() === "false") {
            return (
                <div>
                    <Header/>
                    <Banner/>
                    <div className="container p-3">
                        <div className="home">
                            <div className="row">
                                <div className="col-md-4">
                                    <Categories/>
                                </div>
                                <div className="col-md-8">
                                    <div className="txt">
                                        Nie odnaleziono kategorii, sprawdź adres URL.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }

        return <></>;

    }
}

export default SubcategoriesPage;

import React, {Component} from 'react';
import axios from 'axios';
import {config} from "./../../config";
import "./Galleries.css";
import GalleryThumbnail from './GalleryThumbnail';

class Galleries extends Component {

    constructor() {
        super();
        this.state = {
            galleries: []
        };
        this.loadGalleries = this.loadGalleries.bind(this);
    }

    loadGalleries() {
 
        axios.get(config.apiUrl + 'gallery.php/getAll').then(
            response => {
                this.setState({
                    galleries: response.data.data
                })
            }
        );

    }

    componentDidMount() {
        this.loadGalleries();
    }

    render() {

        if(this.state.galleries.length > 0) {
            return(
                <div className="galleries-card">
                    <div className="galleries">
                        <div className="row d-flex justify-content-start">
                            {this.state.galleries.map((value, index) => {
                                return <GalleryThumbnail
                                    gallery={value}
                                    key={index.toString()}
                                />
                            })}
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <>
                </>
            );
        }

    }

}

export default Galleries;
import React, {Component} from 'react';
import Header from './../header/Header';
import Banner from './../banner/Banner';
import Footer from './../footer/Footer';
import {Link} from "react-router-dom";
import Categories from "../categories/Categories";
import Galleries from "./Galleries";
import ImgCarousel from "./ImgCarousel";
import axios from "axios";
import {config} from "./../../config";
import "./Gallery.css";

import Parser from 'html-react-parser';

class Product extends Component {

    constructor() {
        super();
        this.state = {
            status: "",
            gallery: [],
            photos: []
        };
        this.loadGalleryData = this.loadGalleryData.bind(this);
        this.loadPhotos = this.loadPhotos.bind(this);
    }

    loadGalleryData() {

        let { galleryUrl } = this.props.match.params;
        
        this.setState({
            galleryUrl: galleryUrl
        });

        const galleryUrlData = {
            galleryUrl: galleryUrl
        };

        axios.post(config.apiUrl + 'gallery.php/byGalleryUrl', galleryUrlData).then(
            response => {
                if(response.data.status.toString() === "1") {
                    this.setState({
                        gallery: response.data.data,
                        status: response.data.status
                    });
                    this.loadPhotos();
                }
            }
        ).catch(err => {
            this.setState({
                status: false
            });
        });

    }

    loadPhotos() {

        const galleryDetailsData = {
            galleryID: this.state.gallery[0].galleryID
        };

        axios.post(config.apiUrl + 'gallery.php/getPhotos', galleryDetailsData).then(
            response => {
                if(response.data.status.toString() === "1") {
                    this.setState({
                        photos: response.data.data,
                        status: response.data.status
                    });
                }
            }
        ).catch(err => {
            this.setState({
                status: false
            });
        });

    }

    componentDidMount() {
        
        this.loadGalleryData();

    }

    render() {

        if(this.state.status.toString() === "1") {
            return (
                <div>
                    <Header/>
                    <Banner/>
                    <div className="container p-3">
                        <div className="home">
                            <div className="row">
                                <div className="col-md-4">
                                    <Categories/>
                                </div>
                                <div className="col-md-8">
                                    <div className="gallery-card">
                                        <div className="txt">
                                            <br/>
                                            <br/>
                                            <h1>{this.state.gallery[0].galleryName}</h1>
                                            <hr/>
                                            <br/>
                                            <ImgCarousel galleryName={this.state.gallery[0].galleryName} galleryID={this.state.gallery[0].galleryID} imgFiles={this.state.photos}/>
                                            <br/>
                                            {Parser(this.state.gallery[0].galleryDescription)}
                                        </div>
                                        <br/>
                                        <hr/>
                                        <p className="show-others">Zobacz inne realizacje</p>
                                        <Galleries/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }

        if(this.state.status.toString() === "false") {
            return (
                <div>
                    <Header/>
                    <Banner/>
                    <div className="container-fluid p-3">
                        <div className="home">
                            <div className="row">
                                <div className="col-md-4">
                                    <Categories/>
                                </div>
                                <div className="col-md-8">
                                    <div className="txt">
                                        Nie odnaleziono takiej galerii, sprawdź adres URL.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }

        return <></>;

    }
}

export default Product;
